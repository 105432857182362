import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Typography, Container, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../styles/OurStory.css";
import coupleImage1 from "../assets/images/holdingHandsInGarden.jpg";
import coupleImage2 from "../assets/images/holdingHandsGardenLookingAtEachOther.jpg";
import coupleImage3 from "../assets/images/kissyInGarden.jpg";

const OurStory = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
    {
      src: coupleImage1,
      alt: "Ben and Kelly holding hands in the garden",
    },
    {
      src: coupleImage2,
      alt: "Ben and Kelly looking at each other in the garden",
    },
    {
      src: coupleImage3,
      alt: "Ben and Kelly kissing in the garden",
    },
  ];

  const openModal = (image) => {
    setSelectedImage(image);
    // Prevent background scrolling when modal is open
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setSelectedImage(null);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <motion.div
        className="our-story-page"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <Container className="our-story-container">
          <Typography variant="h1" className="our-story-title">
            Our Love Story Began on Hinge...
          </Typography>
          <Typography variant="h6" className="our-story-subtitle">
            The App Designed to be Deleted
          </Typography>
          <Typography variant="body1" className="our-story-text">
            Ben and Kelly met in May of 2021 on Hinge! Ben must have already
            known the way to Kelly's heart because he started the conversation
            by asking her, "What's your favorite food?" They talked on the phone,
            FaceTimed, and texted until Ben drove out to Lake Zurich in late July
            to meet Kelly in person for the first time! They both went back to
            Iowa City for their junior years of college, and their relationship
            continued to grow. Throughout their last two years of college, they
            created many amazing memories together, from dinner dates to Pop's
            Barbecue to making weekend trips to Wisconsin with friends. After
            graduating in May 2023, Kelly started as a nurse in the Pediatric
            Intensive Care Unit at UI Children's Hospital, and Ben pursued his
            Master's degree in Electrical and Computer Engineering. About a
            year post-grad, on June 5th, 2024, Ben asked Kelly to marry him at
            their special spot on the overlook of Lake McBride, and she said
            YES!!
          </Typography>
          <div className="our-story-images">
            {images.map((image, index) => (
              <img
                key={index}
                src={image.src}
                alt={image.alt}
                className="our-story-image"
                onClick={() => openModal(image)}
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === "Enter") openModal(image);
                }}
                role="button"
                aria-label={`Enlarge image: ${image.alt}`}
              />
            ))}
          </div>
          <Box className="our-story-footer">
            <Typography variant="h4" className="our-story-initials">
              K &B
            </Typography>
            <div className="our-story-divider"></div>
            <Typography variant="h6" className="our-story-date">
              08.16.2025
            </Typography>
          </Box>
        </Container>
      </motion.div>

      <AnimatePresence>
        {selectedImage && (
          <motion.div
            className="modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeModal}
            aria-modal="true"
            role="dialog"
          >
            <motion.div
              className="modal-content"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <IconButton
                aria-label="Close"
                className="modal-close-button"
                onClick={closeModal}
              >
                <CloseIcon />
              </IconButton>
              <img
                src={selectedImage.src}
                alt={selectedImage.alt}
                className="modal-image"
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default OurStory;
